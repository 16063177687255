import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"

import { css } from "styled-components"
import Header from "../components/Header"
import Footer from "../components/Footer"

import styled from "styled-components"

import starsDark from "../images/stars-dark.svg"

const FormContainerStyled = styled.form`
  //background-color: ${({ theme }) => theme.serviceBackground};
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 1em 1.5em;
  padding: 0.5em;
  //box-shadow: ${({ theme }) => theme.boxShadowLevel1};
  @media (min-width: 560px) {
    grid-template-columns: 1fr 3fr;
  }
`
const LabelStyled = styled.label`
  justify-self: start;
  @media (min-width: 560px) {
    justify-self: end;
  }
`
const EraseButtonStyled = styled.input`
  background-color: #c0392b;
  color: whitesmoke;
  border: none;
  border-radius: 1em;
  box-shadow: ${({ theme }) => theme.boxShadowLevel1};
  & :hover {
    cursor: pointer;
  }
  & :active {
    box-shadow: none;
    transform: translateY(4px);
  }
`
const SubmitButton = styled.button`
  background-color: #16a085;
  color: whitesmoke;
  border: none;
  border-radius: 1em;
  box-shadow: ${({ theme }) => theme.boxShadowLevel1};
  & :hover {
    cursor: pointer;
  }
  & :active {
    box-shadow: none;
    transform: translateY(4px);
  }
`

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Me contacter" />
      <div
        css={css`
          background-image: url(${starsDark});
        `}
      >
        <Header />
        <div
          css={css`
            background-color: ${({ theme }) => theme.serviceBackground};
            color: ${({ theme }) => theme.textColor};
            align-self: center;
            justify-content: center;
            margin: 2em auto 2em;
            width: 90%;
            padding: 1em;
            border-radius: 20px;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
            z-index: 10;
            @media only screen and (min-width: 980px) {
              width: 60%;
            }
          `}
        >
          <h1
            css={css`
              text-align: center;
            `}
          >
            <span role="img" aria-label="emoji enveloppe">
              ✉️
            </span>{" "}
            Me contacter
          </h1>
          <p>
            Vous avez un projet et souhaitez en discuter ? J’en serais ravi ! La
            méthode la plus simple est de remplir ce formulaire et je vous
            réponds à la vitesse de l’éclair.
          </p>
          <p>
            Que vous préfériez échanger par mail, téléphone, Skype, Discord, je
            vous recontacte avec la méthode de votre choix. Le tout est de me
            l’indiquer dans votre message bien évidemment.
          </p>
          <p>À votre clavier :)</p>
          <FormContainerStyled
            method="post"
            name="contact"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/contact-success"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <LabelStyled htmlFor="name">Nom</LabelStyled>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Jean Dupont"
              required={true}
            />
            <LabelStyled htmlFor="email">Email</LabelStyled>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="jean.dupont@votredomaine.com"
              required={true}
            />
            <LabelStyled htmlFor="subject">Sujet</LabelStyled>
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="Quel est l'objet de votre message ? "
              required={true}
            />
            <LabelStyled htmlFor="message">Message </LabelStyled>
            <textarea
              name="message"
              id="message"
              rows="5"
              placeholder="Décrivez votre demande le plus précisément possible."
              required={true}
            />
            <EraseButtonStyled type="reset" value="Effacer" />
            <SubmitButton type="submit">Envoyer</SubmitButton>
          </FormContainerStyled>
          <p
            css={css`
              font-size: 0.9em;
            `}
          >
            <em>
              Les données que vous saisissez ne sont conservées que pendant la
              durée de nos échanges et resteront confidentielles.
            </em>
          </p>
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

export default ContactPage
